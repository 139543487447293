import HomeHeroQRCode from './qr-code';
import HomeHeroText from './text';

function HomeHeroSection() {
  return (
    <div className="w-full h-screen relative">
      <HomeHeroText />
      <HomeHeroQRCode
        src="/images/hero/1.png"
        className="top-[-3px] left-[43%] max-desktop:top-[11%] max-tablet:top-[25%]"
        style={{ rotate: 7 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/2.png"
        className="top-[8%] left-[8%] max-desktop:top-[16%] max-tablet:top-[27%] max-tablet:left-[10px]"
        style={{ rotate: -13 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/5.png"
        className="top-[-37px] left-[-148px] max-desktop:left-[-108px] max-tablet:top-[1%] max-tablet:left-[-50px]"
        style={{ rotate: 21 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/7.png"
        className="bottom-[-134px] left-[17%] max-desktop:bottom-[2%] max-tablet:hidden"
        style={{ rotate: 3 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/9.png"
        className="bottom-[-139px] right-[-51px] max-desktop:bottom-[1%] max-desktop:right-[22px]  max-tablet:bottom-[4%]  max-tablet:right-[-21px]"
        style={{ rotate: -6 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/12.png"
        className="top-[-138px] left-[69%] max-desktop:top-[-46px]  max-tablet:top-[1%]  max-tablet:left-auto  max-tablet:right-[-19px]"
        style={{ rotate: 20 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/14.png"
        className="bottom-[16%] left-[5%] max-desktop:bottom-[27%]  max-tablet:left-[-12px] max-tablet:bottom-[30%]"
        style={{ rotate: 24 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/15.png"
        className="bottom-[3%] left-[43%] max-desktop:bottom-[19%] max-tablet:bottom-[18%]"
        style={{ rotate: -9 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/16.png"
        className="bottom-[14%] left-[75%] max-desktop:bottom-[25%] max-desktop:left-auto max-desktop:right-[-22px] max-tablet:bottom-[34%] max-tablet:right-[-50px]"
        style={{ rotate: -29 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/24.png"
        className="top-[26%] left-[-170px] max-desktop:left-[-153px] max-tablet:left-[-78px]  max-tablet:top-[50%] max-tablet:hidden"
        style={{ rotate: -33 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/26.png"
        className="top-[-166px] left-[13%] max-desktop:top-[-128px] max-tablet:top-[-2%] max-tablet:left-[37%]"
        style={{ rotate: 0 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/27.png"
        className="bottom-[24%] right-[-169px] max-desktop:right-[-131px] max-desktop:bottom-[46%] max-tablet:bottom-[50%] max-tablet:right-[-83px]"
        style={{ rotate: 14 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/42.png"
        className="top-[10%] right-[2%]  max-desktop:right-[22px] max-desktop:top-[24%]  max-tablet:right-[-22px]"
        style={{ rotate: -7 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/48.png"
        className="bottom-[-84px] left-[-146px] max-desktop:left-[-120px] max-desktop:bottom-[4%] max-tablet:left-[-12px]  max-tablet:bottom-[3%]"
        style={{ rotate: -25 }}
        initial={{ y: 150 }}
      />
      {/* <HomeHeroQRCode
        src="/images/hero/1.png"
        className="top-[-3px] left-[43%] max-desktop:top-[111px] max-tablet:top-[169px]"
        style={{ rotate: 7 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/2.png"
        className="top-[83px] left-[8%] max-desktop:top-[167px] max-tablet:top-[184px] max-tablet:left-[10px]"
        style={{ rotate: -13 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/5.png"
        className="top-[-37px] left-[-148px] max-desktop:left-[-108px] max-tablet:top-[10px] max-tablet:left-[-50px]"
        style={{ rotate: 21 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/7.png"
        className="bottom-[-134px] left-[17%] max-desktop:bottom-[23px] max-tablet:hidden"
        style={{ rotate: 3 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/9.png"
        className="bottom-[-139px] right-[-51px] max-desktop:bottom-[12px] max-desktop:right-[22px]  max-tablet:bottom-[29px]  max-tablet:right-[-21px]"
        style={{ rotate: -6 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/12.png"
        className="top-[-138px] left-[69%] max-desktop:top-[-46px]  max-tablet:top-[5px]  max-tablet:left-auto  max-tablet:right-[-19px]"
        style={{ rotate: 20 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/14.png"
        className="bottom-[162px] left-[5%] max-desktop:bottom-[274px]  max-tablet:left-[-12px] max-tablet:bottom-[236px]"
        style={{ rotate: 24 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/15.png"
        className="bottom-[30px] left-[43%] max-desktop:bottom-[192px] max-tablet:bottom-[124px]  max-tablet:bottom-[124px]"
        style={{ rotate: -9 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/16.png"
        className="bottom-[144px] left-[75%] max-desktop:bottom-[254px] max-desktop:left-auto max-desktop:right-[-22px] max-tablet:bottom-[229px] max-tablet:right-[34px]"
        style={{ rotate: -29 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/24.png"
        className="top-[267px] left-[-170px] max-desktop:left-[-153px] max-tablet:left-[-78px]  max-tablet:top-[333px]"
        style={{ rotate: -33 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/26.png"
        className="top-[-166px] left-[13%] max-desktop:top-[-128px] max-tablet:top-[-9px] max-tablet:left-[37%]"
        style={{ rotate: 0 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/27.png"
        className="bottom-[240px] right-[-169px] max-desktop:right-[-131px] max-desktop:bottom-[460px] max-tablet:bottom-[336px] max-tablet:right-[-83px]"
        style={{ rotate: 14 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/42.png"
        className="top-[108px] right-[2%]  max-desktop:right-[22px] max-desktop:top-[244px]  max-tablet:right-[-22px]"
        style={{ rotate: -7 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/48.png"
        className="bottom-[-84px] left-[-146px] max-desktop:left-[-120px] max-desktop:bottom-[45px] max-tablet:left-[-12px]  max-tablet:bottom-[23px]"
        style={{ rotate: -25 }}
        initial={{ y: 150 }}
      /> */}
    </div>
  );
}

export default HomeHeroSection;
