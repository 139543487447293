import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

function HomeHeroQRCode(props: {
  className: string;
  src: string;
  initial: { x?: number; y?: number };
  style?: any;
}) {
  const [transition, setTransition] = useState({ duration: 1, delay: 1.0 });
  useEffect(() => {
    setTimeout(() => {
      setTransition({ duration: 0.3, delay: 0 });
    }, 2100);
  }, []);

  // max-desktop:w-[300px]
  return (
    <motion.img
      className={clsx(
        'absolute w-[200px] h-[200px] max-desktop:w-[160px] max-desktop:h-[160px] max-tablet:w-[100px] max-tablet:h-[100px] will-change-transform',
        props.className
      )}
      drag
      dragMomentum={false}
      whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
      whileTap={{
        scale: 1.15,
        transition: { type: 'spring', duration: 0.3, delay: 0 },
        rotate: props.style?.rotate + 3,
      }}
      whileDrag={{
        transition: { duration: 0 },
      }}
      src={props.src}
      style={{ ...props.style, cursor: 'grab' }}
      initial={{
        ...props.initial,
        opacity: 0,
        boxShadow:
          '0 .6021873017743928px .6021873017743928px -1.25px #0000002e,0 2.288533303243457px 2.288533303243457px -2.5px #00000029,0 10px 10px -3.75px #00000010',
      }}
      // onTapStart={() => {
      //   // Custom animation when tap completes
      //   return {
      //     transition: { duration: 0.3, delay: 0 }, // Override transition speed
      //   };
      // }}
      animate={{ x: 0, y: 0, opacity: 1 }}
      transition={transition}
      // onAnimationComplete={() => {
      //   return {
      //     transition: { duration: 0.3, delay: 0 }, // Override transition speed
      //   };
      // }}
    />
  );
}

export default HomeHeroQRCode;
