import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import NavBar from '../../components/core/navbar';
import PrivacyPolicyTitle from '../../components/privacy/title';
import styles from './index.module.css';
import Footer from '../../components/core/footer';

function PrivacyPolicyPage() {
  const { t } = useTranslation('privacy');

  return (
    <div>
      <NavBar />
      <div className="flex justify-center items-start min-h-screen px-6">
        <div className="w-full max-w-[1200px] px-[40px] py-[120px] rounded-[72px] bg-[#E7EEF4] mb-20 max-desktop:rounded-[56px] max-tablet:rounded-[30px] max-tablet:px-[20px] max-tablet:py-[80px]">
          <PrivacyPolicyTitle />
          <div className={styles.container}>{HTMLReactParser(t('text'))}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicyPage;
