import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { homeRoute, impressumRoute, privacyRoute } from '../../../utils/routes';
function Footer() {
  const { t } = useTranslation('common');

  return (
    <div className="flex justify-center px-[30px] pt-[60px] pb-[40px]">
      <div className="max-w-[1200px] w-full">
        <div className="flex max-tablet:justify-center">
          <Link to={homeRoute} className="mr-auto max-tablet:mr-0">
            <img
              src="/images/footer/Logo.png"
              alt="logo"
              className="w-[137px] max-tablet:w-[225px]"
            />
          </Link>
          <a
            href={'mailto:' + t('footer.email')}
            className="text-[18px] leading-[1.5em] max-tablet:hidden"
          >
            {t('footer.email')}
          </a>
        </div>
        <div className="h-[1px] bg-[#E7EEF4] my-2"></div>
        <div className="flex text-[16px] max-tablet:flex-col  max-tablet:items-center">
          <a
            href={'mailto:' + t('footer.email')}
            className="hidden text-[18px] leading-[1.5em] mt-1 max-tablet:block max-tablet:mb-2"
          >
            {t('footer.email')}
          </a>

          <Link
            to={impressumRoute}
            className="leading-[1.5em] hover:underline hover:text-[#7E5DFF] max-tablet:mb-2"
          >
            {t('footer.impressum')}
          </Link>
          <div className="leading-[1.5em] flex-1 text-center max-tablet:order-5">
            {t('footer.copyright')}
          </div>
          <Link
            to={privacyRoute}
            className="leading-[1.5em] hover:underline hover:text-[#7E5DFF] max-tablet:mb-2"
          >
            {t('footer.privacy')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
