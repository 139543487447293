import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home';
import ImpressumPage from './pages/impressum';
import PrivacyPolicyPage from './pages/privacy';
import { homeRoute, impressumRoute, privacyRoute } from './utils/routes';

function App() {
  return (
    <Routes>
      <Route path={homeRoute} element={<HomePage />} />
      <Route path={impressumRoute} element={<ImpressumPage />} />
      <Route path={privacyRoute} element={<PrivacyPolicyPage />} />
    </Routes>
  );
}

export default App;
