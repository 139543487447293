import { Link } from 'react-router-dom';
import { homeRoute } from '../../../utils/routes';

function NavBar() {
  return (
    <Link to={homeRoute} className="flex justify-center py-5">
      <div className="max-w-[1200px] w-full mx-6 mb-20">
        <img src="/images/footer/Logo.png" alt="logo" className="w-[144px]" />
      </div>
    </Link>
  );
}

export default NavBar;
